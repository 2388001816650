import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import tw, { css, styled } from 'twin.macro'

const StyledDiv = styled.div`
  & a {
    ${tw`text-navy`}
  }
  ${tw`block hover:relative hover:z-10 hover:shadow-lg hover:scale-105 transform transition ease-in duration-300`}
`

export default function ProductList({ products }) {
  return (
    <div
      className="mm-px-dynamic"
      css={tw`grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-8`}
    >
      {products.map((p) => {
        return (
          <StyledDiv
            key={p.slug}
            css={[
              tw`flex flex-col justify-between`,
              css`
                background-color: #f9f9f9;
              `,
            ]}
          >
            <Link to={`/${p.uri}`} css={tw`py-6 px-6 lg:py-12 lg:px-12`}>
              <div css={tw`relative flex justify-center`}>
                {p.productImage[0]?.localFile?.childImageSharp
                  ?.gatsbyImageData && (
                  <GatsbyImage
                    css={css`
                      height: 400px;
                      margin-bottom: 2rem;
                    `}
                    height={400}
                    objectFit="contain"
                    image={
                      p.productImage[0]?.localFile?.childImageSharp
                        ?.gatsbyImageData
                    }
                    alt={p.productImage[0]?.title}
                  />
                )}
                {p.productIcons &&
                  p.productIcons.map(
                    (icon, i) =>
                      icon.image[0]?.localFile?.childImageSharp
                        ?.gatsbyImageData && (
                        <GatsbyImage
                          key={i}
                          image={
                            icon.image[0]?.localFile?.childImageSharp
                              ?.gatsbyImageData
                          }
                          alt={icon.image[0]?.title}
                          css={[
                            icon.size === 'small' && tw`w-8`,
                            icon.size === 'medium' && tw`w-16`,
                            icon.size === 'large' && tw`w-32`,
                            icon.placement === 'topLeft' &&
                              tw`absolute left-1 top-1`,
                            icon.placement === 'topRight' &&
                              tw`absolute right-1 top-1`,
                            icon.placement === 'bottomLeft' &&
                              tw`absolute left-1 bottom-1`,
                            icon.placement === 'bottomRight' &&
                              tw`absolute right-1 bottom-1`,
                          ]}
                        />
                      )
                  )}
              </div>
              <h4>{p.title}</h4>
              <div
                dangerouslySetInnerHTML={{ __html: p.description }}
                css={tw`py-6`}
              ></div>
              <span className="link">{p.title}</span>
            </Link>
          </StyledDiv>
        )
      })}
    </div>
  )
}
